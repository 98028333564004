import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EmailIcon from '@mui/icons-material/Email';

export const orderSteps = [
  {
    id: 1,
    title: "Wybierz produkt",
    description: "Zobacz naszą ofertę i wybierz produkt, który spełni Twoje oczekiwania.",
    icon: <AddShoppingCartIcon />,
  },
  {
    id: 2,
    title: "Sprawdź szczegóły",
    description: "Przeczytaj opis produktu, aby dowiedzieć się więcej o jego funkcjach i możliwościach.",
    icon: <QuestionMarkIcon />,
  },
  {
    id: 3,
    title: "Dostosuj produkt",
    description: "Dostosuj produkt do swoich potrzeb, wybierając odpowiednie opcje i parametry.",
    icon: <AutoFixHighIcon />,
  },
  {
    id: 4,
    title: "Kontakt",
    description: "Po konfiguracji zamówienia, skopiuj wygenerowaną wiadomość i wyślij nam maila, lub skontaktuj się telefonicznie.",
    icon: <EmailIcon />,
  }
];