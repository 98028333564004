import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <img
        src="/assets/404.png"
        alt="error-404"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          maxWidth: "950px",
        }}
      ></img>
    </div>
  );
};

export default PageNotFound;
