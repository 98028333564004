import React from "react";
import { Container } from "@mui/material";
import ShortOfferSteps from "./ShortOfferSteps";
import "./ShortOffer.scss";

const ShortOffer = () => {
  return (
      <div className="shortoffer" id="shortoffer">
        <Container>
          <h3>Indywidualne podejście, to podstawa!</h3>
          <p>
            Oferujemy indywidualne rozwiązania, dopasowane dokładnie do Twoich
            potrzeb i wymagań. W przeciwieństwie do konkurencji, nie polegamy na
            gotowych szablonach ani uniwersalnych wzorcach. Zamiast tego, każdy
            projekt jest tworzony od podstaw, z uwzględnieniem Twojej wizji,
            specyficznych oczekiwań oraz celu, jaki chcesz osiągnąć. Dzięki temu
            masz pewność, że końcowy produkt będzie nie tylko unikalny, ale
            także w pełni funkcjonalny i dopasowany do Twojej marki oraz
            odbiorców. Nasze podejście gwarantuje, że każda strona, sklep czy
            panel zarządzania jest wyjątkowy, tak jak Twoja firma.
          </p>
        </Container>

        <ShortOfferSteps />
      </div>
  );
};

export default ShortOffer;
