import React from "react";
import "./Banner.scss";
import { Container } from "@mui/material";
import Slide from "react-reveal/Slide";
import Button from "../../modules/Button/Button";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div id="banner" className="banner">
        <Slide left>
          <div className="shape"></div>
        </Slide>
        <div className="left-section">
          <div className="title">
            <Slide right>
              <h2>Chcesz zaistnieć w sieci, ale nie masz pomysłu?</h2>
            </Slide>
            <Slide left>
              <h1>
                <span>Outside the Box Web Service</span> - Projektujemy strony,
                które spełniają Twoje oczekiwania.
              </h1>
            </Slide>
            <Button
              color="dark"
              variant="platinum"
              onClick={() => navigate("/oferta")}
            >
              sprawdź ofertę
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Banner;
