export const errorsInitialValues = {
  name: false,
  email: false,
  message: false,
};

export const subjects = [
  "Wizytówka",
  "E-commerce",
  "Strona internetowa",
  "Panel użytkownika",
  "Inne",
]

export const initialValues = {
  name: "",
  email: "",
  message: "",
  subject: subjects[0],
}