export const footerInfo = {
  description: <span style={{color: 'grey', opacity: ".8"}}>Projektujemy strony, które spełniają Twoje oczekiwania.</span>,
  contact: {
    phone: <p>+48 698-008-803</p>,
    email: <p>obws@obws.pl</p>,
    nip: <p>NIP: 7692250916</p>,
    address: <p>Bełchatów, 97-400</p>,
    address2: <p>ul. Smugowa 18</p>,
    regon: <p>REGON: 529201967</p>,
    company: <p>Outside the Box Web Service &copy; Eryk Szczepanek</p>
  },
  logo: <img src="/assets/obws-logo.png" alt="obws-logo" />,
  socialMedia: {
    facebook: {
      link: "https://www.facebook.com/obwspl",
      icon: <i className="fab fa-facebook-f"></i>,
    },
    instagram: {
      link: "https://www.instagram.com/obwspl/",
      icon: <i className="fab fa-instagram"></i>,
    },
    linkedin: {
      link: "https://www.linkedin.com/company/obwspl",
      icon: <i className="fab fa-linkedin-in"></i>,
    },
  },
};
