export const offerTexts = [
  {
    id: 1,
    title: <h3>Strony Wizytówki</h3>,
    description: (
      <p>
        Nasze strony wizytówki to idealne rozwiązanie dla firm, które chcą
        zaznaczyć swoją obecność w sieci i profesjonalnie zaprezentować swoją
        ofertę. Tworzymy strony, które nie tylko pięknie wyglądają, ale są
        również funkcjonalne i łatwe w nawigacji. Każda strona wizytówka jest
        starannie zaprojektowana z myślą o Twojej marce, a treść i design są
        dostosowane do specyficznego przekazu, jaki chcesz skierować do swoich
        klientów. Dbamy o to, aby Twoja strona wizytówka była w pełni
        responsywna, dzięki czemu będzie się świetnie prezentować na każdym
        urządzeniu – od komputerów po smartfony.
      </p>
    ),
  },
  {
    id: 2,
    title: <h3>Małe Sklepy Internetowe</h3>,
    description: (
      <p>
        Dla przedsiębiorców, którzy chcą rozwijać swoją działalność online,
        oferujemy projektowanie i wdrażanie małych sklepów internetowych.
        Zrozumienie specyfiki Twojego biznesu i produktów pozwala nam na
        stworzenie sklepu, który nie tylko będzie estetyczny, ale przede
        wszystkim intuicyjny dla użytkowników i zoptymalizowany pod kątem
        sprzedaży. Każdy sklep internetowy, który tworzymy, jest w pełni
        dostosowany do Twoich potrzeb – od personalizacji procesu zakupowego po
        integrację z systemami płatności i zarządzania zamówieniami. Nasze
        rozwiązania są skalowalne, co oznacza, że sklep może rosnąć wraz z Twoim
        biznesem.
      </p>
    ),
  },
  {
    id: 3,
    title: <h3>Panele Użytkownika</h3>,
    description: (
      <p>
        Oferujemy także zaawansowane panele użytkownika, które pozwalają na
        skuteczne zarządzanie różnorodnymi procesami w Twojej firmie. Tworzymy
        panele dostosowane do specyficznych wymagań, umożliwiające zarządzanie
        danymi, użytkownikami, treściami oraz operacjami biznesowymi. Nasze
        panele użytkownika są zaprojektowane z myślą o maksymalnej użyteczności
        i wygodzie, co sprawia, że nawet złożone operacje stają się proste i
        intuicyjne do wykonania. Każdy panel jest wyposażony w niezbędne
        funkcje, które pomagają w codziennej pracy i wspierają rozwój Twojego
        biznesu.
      </p>
    ),
  },
];
