export const realizationsConfig = [
  {
    id: 1,
    title: 'Rentali',
    description: 'Strona internetowa dla rezerwacji zakwaterowań wypoczynkowych',
    img: '/assets/realizations/rentali.png',
    url: 'https://rentali.pl'
  },
  {
    id: 2,
    title: 'Bezpieczny schowek',
    description: 'Strona internetowa dla rezerwacji schowków/kontenerów',
    img: '/assets/realizations/bezpiecznyschowek.png',
    url: 'https://bezpiecznyschowek.pl'
  },
  {
    id: 3,
    title: 'MSPro Schody',
    description: 'Strona internetowa dla producenta schodów półkowych',
    img: '/assets/realizations/msproschody.png',
    url: 'https://msproschody.pl'
  },
  {
    id: 4,
    title: 'HopBite Foods',
    description: 'Strona internetowa dla firmy cateringowej specjalizującej się w kuchni azjatyckiej',
    img: '/assets/realizations/hopbitefoods.png',
    url: 'https://hopbitefoods.pl'
  }
]