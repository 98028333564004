import React from "react";
import "./Realizations.scss";
import { realizationsConfig } from "./realizations.config";
import { Container, Tooltip, Modal } from "@mui/material";
import Button from '../../modules/Button/Button';

const Realizations = () => {
  const [image, setImage] = React.useState(null);
  const handleImageClick = (img) => {
    setImage(img);
  };

  return (
    <div className="realizations" id="realizations">
      <h2>
        <span>#</span> Realizacje
      </h2>
      <div className="realizations-wrapper">
        {realizationsConfig.map((realization, index) => (
          <div className={`realization ${index % 2 === 0 ? 'dark-theme' : ''}`} key={realization.id}>
            <Container>
              <div className="realization-wrapper">
                <Tooltip title="Zeskroluj aby zobaczyć więcej zdjęcia" placement="top">
                <div className="realization-img" onClick={() => handleImageClick(realization.img)}>
                  <img src={realization.img} alt={realization.title} />
                </div>
                </Tooltip>

                <div className="realization-content">
                  <h3>{realization.title}</h3>
                  <p>{realization.description}</p>
                  <Button variant="platinum" color="dark">Zobacz stronę</Button>
                </div>
              </div>
            </Container>
          </div>
        ))}
        {image && (
          <Modal
            open={image !== null}
            onClose={() => setImage(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', overflowY: 'scroll' }}
          >
            <div className="modal-image">
              <img src={image} alt="modal" style={{maxWidth: '650px'}}/>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Realizations;
