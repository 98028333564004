import React from "react";
import "./Offer.scss";
import { Container } from "@mui/material";
import Button from "../../modules/Button/Button";
import { offerTexts } from "./offer.config";

const Offer = () => {
  return (
    <div className="offer">
      <div className="offer-wrapper">
        <div className="offer-text">
          <Container>
            <h2>
              <span>#</span> Oferta
            </h2>
            <p>
              W naszej firmie kładziemy ogromny nacisk na tworzenie
              indywidualnych rozwiązań, które w pełni odpowiadają na specyficzne
              potrzeby i oczekiwania naszych klientów. Każdy projekt traktujemy
              jako unikalne wyzwanie, dlatego nigdy nie korzystamy z gotowych
              szablonów. Zamiast tego, projektujemy i budujemy strony
              internetowe, sklepy online oraz panele użytkownika od podstaw, z
              uwzględnieniem Twojej wizji i celów biznesowych.
            </p>
          </Container>
        </div>
        {offerTexts.map((offer, index) => (
          <div
            key={offer.id}
            className={`offer-text ${index % 2 === 0 ? "dark-theme" : ""}`}
          >
            <Container>
              {" "}
              {offer.title}
              {offer.description}
            </Container>
          </div>
        ))}
      </div>
      <Button variant="green">przejdź do konfiguratora</Button>
    </div>
  );
};

export default Offer;
