import React from "react";
import "./Contact.scss";
import {
  TextField,
  Box,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Button from "../../modules/Button/Button";
import { errorsInitialValues, initialValues, subjects } from "./contact.config";
import dataServiceInstance from "../../services/DataService";

const Contact = () => {
  const [formValues, setFormValues] = React.useState(initialValues);
  const [isSent, setIsSent] = React.useState({
    isSent: false,
    error: false,
    message: "",
  });
  const [errors, setErrors] = React.useState(errorsInitialValues);

  const handleChange = (e, key) => {
    if (key === "subject") {
      setFormValues({ ...formValues, [key]: e.target.value });
      return;
    }

    setErrors(errorsInitialValues);
    setFormValues({ ...formValues, [key]: e });
  };

  const handleSubmit = async () => {
    setErrors(errorsInitialValues);
    if (!formValues.name || formValues.name.length < 3) {
      setErrors({ ...errors, name: "Imię musi zawierać minimum 3 znaki" });
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      formValues.email
    );
    if (!emailRegex) {
      setErrors({
        ...errors,
        email: "Email musi być w poprawnym formacie - example@example.com",
      });
      return;
    }
    if (!formValues.message || formValues.message.length < 10) {
      setErrors({
        ...errors,
        message: "Wiadomość musi zawierać minimum 10 znaków",
      });
      return;
    }
    const data = {
      name: formValues.name,
      email: formValues.email,
      message: formValues.message,
      subject: formValues.subject,
    };

    const emailSent = await dataServiceInstance.sendEmail(data);
    if(emailSent.isError) {
      console.error("Error:", emailSent.error);
      setIsSent({ isSent: false, error: true, message: "Coś poszło nie tak. Spróbuj ponownie później." });
      return;
    }

    setIsSent({ isSent: true, error: false, message: "Wiadomość została wysłana. Dziękujemy!" });
    setFormValues(initialValues);
  };

  return (
    <div className="contact" id="contact">
      <h2>
        <span>#</span> Kontakt
      </h2>
      {(isSent.isSent || isSent.error) && (
        <Alert
          severity={isSent.error ? "error" : "success"}
          sx={{
            width: "350px",
            color: "white",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "start",
          }}
        >
          {isSent.message}
        </Alert>
      )}
      <Box
        sx={{ color: "white", width: "350px" }}
        gap={2}
        display={"flex"}
        flexDirection={"column"}
      >
        <TextField
          label="Imię"
          id="name"
          variant="filled"
          value={formValues.name}
          onChange={(e) => handleChange(e.target.value, "name")}
          error={errors.name}
          helperText={errors.name && errors.name}
        />
        <TextField
          label="Email"
          id="email"
          variant="filled"
          value={formValues.email}
          onChange={(e) => handleChange(e.target.value, "email")}
          error={errors.email}
          helperText={errors.email && errors.email}
        />
        <FormControl variant="filled">
          <InputLabel id="subject-label">Temat</InputLabel>
          <Select
            labelId="subject-label"
            id="subject-select"
            value={formValues.subject}
            onChange={(e) => handleChange(e, "subject")}
          >
            {subjects.map((subject) => (
              <MenuItem key={subject} value={subject}>
                {subject}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Wiadomość"
          id="message"
          variant="filled"
          multiline
          rows={4}
          value={formValues.message}
          onChange={(e) => handleChange(e.target.value, "message")}
          error={errors.message}
          helperText={errors.message && errors.message}
        />
        <Button variant="green" onClick={handleSubmit}>
          wyślij wiadomość
        </Button>
      </Box>
    </div>
  );
};

export default Contact;
