import React from "react";

import Banner from "../../components/Banner/Banner";
import ShortOffer from "../../components/ShortOffer/ShortOffer";
import Footer from "../../components/Footer/Footer";
import Offer from "../Offer/Offer";
import CMS from "../CMS/CMS";
import Realizations from "../Realizations/Realizations";
import Contact from "../Contact/Contact";
import Configurator from "../Configuratior/Configurator";
import PageNotFound from "../PageNotFound/PageNotFound.js";

import { Routes, Route } from "react-router-dom";

const MainPage = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Banner />
              <ShortOffer />
            </>
          }
        />
        <Route path="/oferta" element={<Offer />} />
        <Route path="/cms" element={<CMS />} />
        <Route path="/realizacje" element={<Realizations />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/konfigurator" element={<Configurator />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default MainPage;
