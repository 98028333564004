import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NavigationMobile = ({ bookmarks }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (href) => {
    navigate(href);
    setIsOpen(false);
  };

  return (
    <div className="navigation-mobile">
      <div className="dropdown">
        <span onClick={handleMenu}>
          <MenuIcon />
        </span>
        {isOpen && <div className='menu-items'>
          {bookmarks.map((bookmark) => (
            <MenuItem
              key={`bookmarks-${bookmark.id}`}
              onClick={() => handleNavigation(bookmark.href)}
            >
              {bookmark.value}
            </MenuItem>
          ))}
        </div>}
      </div>

    </div>
  );
};

export default NavigationMobile;
