import React from 'react';
import './CMS.scss';

const CMS = () => {
  return (
    <div className='cms' >
      <img src="/assets/under-construction.png" alt="under-construction"/>
    </div>
  )
}

export default CMS