import { MenuItem } from "@mui/material";

export const bookmarks = [
  // {
  //   id: 1,
  //   value: 'Firma',
  //   href: '/firma',
  // },
  {
    id: 2,
    value: "Kontakt",
    href: "/kontakt",
  },
  {
    id: 3,
    value: "Oferta",
    href: "/oferta",
  },
  {
    id: 4,
    value: "Realizacje",
    href: "/realizacje",
  },
  {
    id: 5,
    value: "CMS",
    href: "/cms",
  },
  {
    id: 6,
    value: "Konfigurator",
    href: "/konfigurator",
  }
];

export const renderNavigationItems = (handleNavigation) => {
  return bookmarks.map((bookmark) => (
    <div className="desktop-bookmark-item" key={`bookmarks-${bookmark.id}`}>
      <MenuItem
        key={`bookmarks-${bookmark.id}`}
        onClick={() => handleNavigation(bookmark.href)}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        {bookmark.value}
      </MenuItem>
    </div>
  ));
};
