import React from "react";
import { bookmarks } from "./navigation.config";
import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";
import "./Navigation.scss";
import { Container } from "@mui/material";

const Navigation = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="navigation" id="navigation">
      <Container>
        <div className="navigation-wrapper">
          <div className="navigation-logo">
            <a href="/" ><img src="/assets/obws-logo.png" alt="logo" /></a>
          </div>
          {isMobile ? (
            <NavigationMobile bookmarks={bookmarks} />
          ) : (
            <NavigationDesktop bookmarks={bookmarks} />
          )}
        </div>
      </Container>
    </div>
  );
};

export default Navigation;
