import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { websiteTypes, websiteTypesOptions } from "./configurator.config";
import "./Configurator.scss";
import Button from "../../modules/Button/Button";
import dataServiceInstance from "../../services/DataService";

const Configurator = () => {
  const [formValues, setFormValues] = useState({
    type: "",
    pagesCount: 1,
    gallery: false,
    contactForm: false,
    allowFromCMS: false,
    email: "",
  });

  const [isSent, setIsSent] = React.useState({
    isSent: false,
    error: false,
    message: "",
  });

  const [errors, setErrors] = useState({ email: false });

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      type: selectedType,
      pagesCount: websiteTypesOptions[selectedType].minPagesCount,
      gallery: websiteTypesOptions[selectedType].gallery.isOn,
      contactForm: websiteTypesOptions[selectedType].contactForm.isOn,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const calculateEstimatedPrice = () => {
    if (formValues.type) {
      const { pagesCount, gallery, contactForm } = formValues;
      return websiteTypesOptions[formValues.type].estimatedPrice(
        pagesCount,
        gallery,
        contactForm,
        websiteTypesOptions[formValues.type].startingPrice
      );
    }
    return 0;
  };

  const handleSubmit = () => {
    setErrors({ email: false });
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      formValues.email
    );

    if (!emailRegex) {
      setErrors({
        ...errors,
        email: "Email musi być w poprawnym formacie - example@example.com",
      });
      return;
    }

    const data = {
      email: formValues.email,
      subject: "Zamówienie z konfiguratora",
      message: `Zamówienie z konfiguratora: ${
        formValues.type
      }, ilość podstron: ${formValues.pagesCount}, galeria: ${
        formValues.gallery ? "tak" : "nie"
      }, formularz kontaktowy: ${
        formValues.contactForm ? "tak" : "nie"
      }, cena: ${calculateEstimatedPrice()} złotych netto`,
    };

    const response = dataServiceInstance.sendEmail(data);
    if (response.isError) {
      console.error("Error:", response.error);
      setIsSent({
        isSent: false,
        error: true,
        message: "Coś poszło nie tak. Spróbuj ponownie później.",
      });
      return;
    }

    setIsSent({
      isSent: true,
      error: false,
      message: "Zamówienie zostało wysłane. Dziękujemy!",
    });
  };

  return (
    <div className="configurator">
      <h2>
        <span>#</span> Konfigurator
      </h2>
      {(isSent.isSent || isSent.error) && (
        <Alert severity={isSent.error ? "error" : "success"}>
          {isSent.message}
        </Alert>
      )}

      <Box
        gap={2}
        display="flex"
        flexDirection={"column"}
        sx={{ maxWidth: "300px", minWidth: "250px" }}
        justifyContent={"center"}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="type-label">Rodzaj strony</InputLabel>
          <Select
            labelId="type-label"
            id="type-select"
            value={formValues.type}
            onChange={handleTypeChange}
            label="Rodzaj strony"
          >
            {Object.entries(websiteTypes).map(([key, type]) => (
              <MenuItem key={type.id} value={key} disabled={type.disabled}>
                {type.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {formValues.type && (
          <>
            <TextField
              label="Liczba zakładek"
              type="number"
              name="pagesCount"
              value={formValues.pagesCount}
              onChange={handleInputChange}
              inputProps={{
                min: websiteTypesOptions[formValues.type].minPagesCount,
                max: websiteTypesOptions[formValues.type].maxPagesCount,
              }}
              fullWidth
              margin="normal"
              helperText={`Od ${
                websiteTypesOptions[formValues.type].minPagesCount
              } do ${websiteTypesOptions[formValues.type].maxPagesCount}`}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.gallery}
                    onChange={handleCheckboxChange}
                    name="gallery"
                    disabled={
                      websiteTypesOptions[formValues.type].gallery.disabled
                    }
                  />
                }
                label="Galeria"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.contactForm}
                    onChange={handleCheckboxChange}
                    name="contactForm"
                    disabled={
                      websiteTypesOptions[formValues.type].contactForm.disabled
                    }
                  />
                }
                label="Formularz kontaktowy"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.allowFromCMS}
                    onChange={handleCheckboxChange}
                    name="allowFromCMS"
                    disabled={
                      !websiteTypesOptions[formValues.type].allowFromCMS
                    }
                  />
                }
                label="Zarządanie zasobami z CMS"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="email"
                label="Email"
                name="email"
                value={formValues.email}
                placeholder="example@example.com"
                onChange={handleInputChange}
                error={errors.email && !!errors.email}
                helperText={errors.email && errors.email}
              />
            </FormGroup>

            <Typography variant="h6" gutterBottom>
              Szacowana cena od{" "}
              <span className="config">{calculateEstimatedPrice()}</span>{" "}
              złotych netto!*
            </Typography>

            <Button variant="green" color="primary" onClick={handleSubmit}>
              Wyślij wygenerowane zamówienie do analizy
            </Button>
            <Typography
              variant="caption"
              gutterBottom
              sx={{ fontSize: "10px" }}
            >
              *Cena jest jedynie informacyjna oraz jest szacowania
              automatycznie, więcej szczegółów będzie dostepne w wiadomości
              zwrotnej po analizie zamówienia.
            </Typography>
          </>
        )}
      </Box>
    </div>
  );
};

export default Configurator;
