import axios from "axios";

class DataService {
  API_URL = process.env.REACT_APP_API_URL;

  async sendEmail(data) {
    try {
      const response = await axios.post(`${this.API_URL}/sendEmail`, data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return {
        isError: true,
        error: error,
      };
    }
  }
}

const dataServiceInstance = new DataService();
export default dataServiceInstance;
