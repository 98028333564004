import React from "react";
import { orderSteps } from "./shortOffer.config";
import { Container } from "@mui/material";
import Slide from "react-reveal/Slide";

const ShortOfferSteps = () => {
  return (
    <div className="shortoffer-steps">
      <Container>
        <div className="shape"></div>
        <Slide right>
          <h3>Jak to u nas wygląda?</h3>
        </Slide>
        <Slide left>
          {orderSteps.map((step, index) => (
            <div key={step.id} className="shortoffer-step">
              {index !== orderSteps.length - 1 && (
                <div className="title-line"></div>
              )}

              <div className="title">
                {step.icon}
                <h4>{step.title}</h4>
              </div>
              <p>{step.description}</p>
            </div>
          ))}
        </Slide>
      </Container>
    </div>
  );
};

export default ShortOfferSteps;
