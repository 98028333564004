export const websiteTypes = {
  business: {
    id: 1,
    value: "Strona wizytówka",
    name: "business",
    startingPrice: 750,
    description:
      "Strona wizytówka to podstawowa forma prezentacji firmy w internecie. Składa się z kilku podstron, które zawierają informacje o firmie, jej ofercie, kontaktach oraz lokalizacji. Strona wizytówka to idealne rozwiązanie dla małych firm, które chcą zaistnieć w internecie.",
    estimatedTime: 1,
    disabled: false,
  },
  shop: {
    id: 2,
    value: "Dedykowany sklep internetowy",
    name: "shop",
    startingPrice: 6000,
    description:
      "Sklep internetowy to platforma, która umożliwia sprzedaż produktów online. Sklep internetowy to idealne rozwiązanie dla firm, które chcą zwiększyć swoją sprzedaż oraz dotrzeć do większej liczby klientów.",
    estimatedTime: 6,
    disabled: false,
  },
  cms: {
    id: 3,
    value: "Panel zarządzania",
    name: "cms",
    startingPrice: 4000,
    description:
      "Panel zarządzania to platforma, która umożliwia zarządzanie treścią strony internetowej. Panel zarządzania to idealne rozwiązanie dla firm, które chcą samodzielnie zarządzać treścią swojej strony internetowej.",
    estimatedTime: 1,
    disabled: false,
  },
  info: {
    id: 4,
    value: "Strona informacyjna",
    name: "info",
    startingPrice: 1000,
    description:
      "Strona informacyjna to platforma, która umożliwia prezentację informacji na temat firmy, jej oferty, kontaktów oraz lokalizacji. Strona informacyjna to idealne rozwiązanie dla firm, które chcą zaistnieć w internecie.",
    estimatedTime: 1,
    disabled: false,
  },
};

export const websiteTypesOptions = {
  business: {
    minPagesCount: 1,
    maxPagesCount: 5,
    pagesCount: 1,
    startingPrice: websiteTypes["business"].startingPrice,
    gallery: {
      isOn: false,
      disabled: false
    },
    contactForm: {
      isOn: false,
      disabled: false
    },
    estimatedPrice: function(pages, gallery, contactForm, startingPrice) {
      const g = gallery ? 500 : 0;
      const c = contactForm ? 500 : 0;
      const p = pages * 350;
      return p + g + c + startingPrice;
    },
    allowFromCMS: false,
  },
  shop: {
    minPagesCount: 3,
    maxPagesCount: 10,
    pagesCount: 5,
    startingPrice: websiteTypes["shop"].startingPrice,
    gallery: {
      isOn: true,
      disabled: true,
    },
    contactForm: {
      isOn: true,
      disabled: true,
    },
    estimatedPrice: function(pages, gallery, contactForm, startingPrice) {
      const g = gallery ? 500 : 0;
      const c = contactForm ? 500 : 0;
      const p = pages * 550;
      return p + g + c + startingPrice;
    },
    allowFromCMS: false,

  },
  cms: {
    minPagesCount: 1,
    maxPagesCount: 1,
    pagesCount: 1,
    startingPrice: websiteTypes["cms"].startingPrice,
    gallery: {
      isOn: false,
      disabled: true,
    },
    contactForm: {
      isOn: false,
      disabled: true,
    },
    estimatedPrice: function(pages, gallery, contactForm, startingPrice) {
      const g = gallery ? 500 : 0;
      const c = contactForm ? 500 : 0;
      const p = pages * 450;
      return p + g + c + startingPrice;
    },
    allowFromCMS: false,

  },
  info: {
    minPagesCount: 1,
    maxPagesCount: 5,
    pagesCount: 1,
    startingPrice: websiteTypes["info"].startingPrice,
    gallery: {
      isOn: false,
      disabled: false,
    },
    contactForm: {
      isOn: false,
      disabled: false,
    },
    estimatedPrice: function(pages, gallery, contactForm, startingPrice) {
      const g = gallery ? 500 : 0;
      const c = contactForm ? 500 : 0;
      const p = pages * 350;
      return p + g + c + startingPrice;
    },
    allowFromCMS: false,

  },
}