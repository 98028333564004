import React from "react";
import "./Footer.scss";
import { footerInfo } from "./footer.config";
import Container from "@mui/material/Container";

const Footer = () => {
  return (
    <Container>
      <div className="footer" id="kontakt">
        <div className="footer-content">
          <div className="footer-info">
            {footerInfo.logo}
            {footerInfo.description}
          </div>
          <div className="footer-contact">
            <h4>Adres</h4>
            {footerInfo.contact.address}
            {footerInfo.contact.address2}
            <h4>Kontakt</h4>
            {footerInfo.contact.phone}
            {footerInfo.contact.email}
            <h4>Firma</h4>
            {footerInfo.contact.nip}
            {footerInfo.contact.regon}
            {footerInfo.contact.company}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
