import "./App.scss";
import "./styles/settings.scss";
import Root from "./Root";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
