import React from 'react'
import MainPage from './pages/MainPage/MainPage'
import Navigation from './components/Navigation/Navigation'

const Root = () => {
  return (
    <div>
      <Navigation />
      <MainPage />
    </div>
  )
}

export default Root