import React from "react";
import { useNavigate } from "react-router-dom";
import { renderNavigationItems } from "./navigation.config";

const NavigationDesktop = () => {
  const navigate = useNavigate();

  const handleNavigation = (href) => {
    navigate(href);
  };

  return (
    <div className="navigation-desktop">
      {renderNavigationItems(handleNavigation)}
    </div>
  );
};

export default NavigationDesktop;
