import React from 'react'
import { colorSettings } from '../../styles/settings';
import './Button.scss';

const Button = ({color, variant = "primary", value, children, onClick }) => {
  const val = value || children;
  const styles = {
    color: colorSettings[color] || 'white',
    backgroundColor: colorSettings[variant] || 'green',
    padding: '10px 25px',
    cursor: 'pointer'
  };

  return (
    <span className='btn' onClick={onClick} style={styles}>{val.toUpperCase()}</span>
  )
}

export default Button